<template>
	<div>
		<!-- 公共头部 -->
	<Header></Header>
		
		<div class="section1">
			<div class="box">
				<img class="pic" :src="newsInfo.info.img" />
				<div class="content">
					<h2 class="title">{{newsInfo.info.name}}</h2>
					<div class="desc" v-html="newsInfo.info.description"></div>
				</div>
			</div>
			<div class="singlepage" v-html="newsInfo.info.content"></div>
		</div>
		<div class="section2">
			<h2 class="cate-title">相关资质</h2>
			<ul class="list clearfix">
				<li class="item fl" v-for="(item,index) in imglist" :key="index">
					<img class="pic" :src="item" />
				</li>
			</ul>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				newsInfo: {
					info: ''
				},
				imglist: [],
			}
		},
		watch: {
			$route(to,form) {
				if(to.path == form.path){
					this.getNewsInfo();
				}
			}
		},
		activated() {
			this.getNewsInfo();
		},
		deactivated() {
			this.newsInfo = {
				info: ''
			};
			this.imglist = [];
		},
		methods: {
			async getNewsInfo() {
				var id = this.$route.query.aid;
				var data = {
					id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/school/teacherDetail', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('教师风采详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var newsInfo = res.data;
					if(newsInfo.info.img.substring(0,4) != 'http'){
						newsInfo.info.img = this.$store.state.http + newsInfo.info.img
					}
					this.newsInfo = newsInfo;
					var imglist = newsInfo.info.imglist;
					for(var i = 0; i < imglist.length; i++){
						if(imglist[i] && imglist[i].substring(0,4) != 'http'){
							imglist[i]=this.$store.state.http + imglist[i]
						}
					}
					this.imglist = imglist;
				} else {
					this.newsInfo = {
						info: ''
					};
					this.imglist = [];
				}
			}
		}
	};
</script>

<style scoped>
	.section1{
		margin-top: 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
	}
	.section1 .box{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 0.4rem 0.2rem;
		border-bottom: 0.02rem solid #e0e0e0;
	}
	.section1 .box .pic{
		width: 1.7rem;
		height: 1.7rem;
		object-fit: contain;
	}
	.section1 .box .content{
		width: 5.1rem;
	}
	.section1 .box .content .title{
		font-size: 0.36rem;
		color: #333333;
		margin-bottom: 0.1rem;
	}
	.section1 .box .content .desc{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.42rem;
	}
	.section1 .singlepage{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.44rem;
		padding: 0.3rem;
	}
	
	.section2{
		margin-top: 0.33rem;
		margin-bottom: 0.7rem;
	}
	.section2 .cate-title{
		height: 0.9rem;
		display: flex;
		align-items: center;
		font-size: 0.32rem;
		color: #333333;
		padding-left: 0.5rem;
		position: relative;
	}
	.section2 .cate-title::before{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.05rem;
		height: 0.26rem;
		background: #ff6000;
		left: 0.3rem;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.section2 .list{
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		padding: 0.4rem 0.3rem;
	}
	.section2 .list .item{
		width: 3.35rem;
		height: 2.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0.02rem solid #e6e6e6;
		margin-right: 0.2rem;
		margin-top: 0.2rem;
	}
	.section2 .list .item:nth-child(1),
	.section2 .list .item:nth-child(2){
		margin-top: 0;
	}
	.section2 .list .item:nth-child(2n){
		margin-right: 0;
	}
	.section2 .list .item .pic{
		max-height: 100%;
	}
</style>